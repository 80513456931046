<template>
  <ModaleGenerique ref="modale" :width="'350px'">
    <template #activator="{props: activatorProps}">
      <div
        :id="fournisseur.id"
        class="garage_notation included font-size-12 d-flex align-center rounded-lg pa-1 mt-2"
        v-bind="activatorProps"
      >
        <icon-star filled class="mr-1" style="font-size: 17px" />
        <div v-if="fournisseur.noteMoyenne" id="noteMoy">
          <span class="black--text">{{ fournisseur.noteMoyenne.toFixed(1) }}</span>
        </div>
        <span v-else class="grey--text text--lighten-1">-</span>
        <span class="grey--text text--lighten-1">/5</span>
      </div>
    </template>
    <template #content>
      <span class="mt-2 font-size-12 text-center color-bouton">Qu'avez vous pensé du garage</span>
      <span class="font-size-12 font-weight-bold text-center color-bouton" color="var(--theme-icone)">
        {{ fournisseur.nomCommercialUsuel }} ?
      </span>
      <form class="d-flex flex-column justify-center" @submit.prevent="sendNote()">
        <Rating
          v-model="rating"
          active-color="#F6A90D"
          color="grey-lighten-1"
          empty-icon="mdi-star"
          length="5"
          size="25"
          class="mt-2 d-flex justify-center"
          :class="{'mb-2': rating > 2}"
          name="note"
        />
        <TextareaInput
          v-if="rating && rating < 3"
          hint="1500 caractères max"
          placeholder="Pouvez-vous nous en dire plus sur votre experience ?"
          name="commentaire"
          width="100%"
          class="mx-auto mt-3"
        />
        <v-btn
          v-if="rating"
          type="submit"
          dark
          class="rounded-pill mx-auto mt-2 px-6"
          color="var(--theme-icone)"
          width="200px"
        >
          Valider
        </v-btn>
      </form>
    </template>
  </ModaleGenerique>
</template>

<script setup lang="ts">
import TextareaInput from '~/components/ui/inputs/textarea-input.vue';
import ModaleGenerique from '~/components/ui/modale/modale-generique.vue';
import {object, string, number} from 'yup';
import type {NotationDTO} from '~/types/typesFournisseur';

const fournisseursStore = useFournisseursStore();
const vehiculeStore = useVehiculeStore();

const props = defineProps<{
  fournisseur: Fournisseur;
  isNoteSent: boolean;
}>();

const rating = ref<number>(props.fournisseur.noteConducteur ? props.fournisseur.noteConducteur : 0);
const modale = ref();

const {handleSubmit} = useForm({
  validationSchema: object({
    note: number().required(useT('validation.notationGarage')),
    commentaire: string()
      .when('note', {
        is: (value: number) => value <= 2, // Ensure validation only when rating is 2 or below
        then: (schema) => schema.required(useT('validation.expliquerNotation')),
      })
      .max(1500, useT('validation.commentaireMax')),
  }),
});

const sendNote = handleSubmit(async (values) => {
  const data: NotationDTO = {
    idFournisseur: props.fournisseur.id,
    numeroDossier: vehiculeStore.getNumeroDossier(),
    note: rating.value,
    commentaire: values.commentaire?.trim(),
    createdAt: new Date(Date.now()),
    immatriculation: vehiculeStore.getImmatriculation(),
    nomGarage: props.fournisseur.nomCommercialUsuel,
    adresse: props.fournisseur.adresse.adresse1
      .toLowerCase()
      .concat(' ')
      .concat(props.fournisseur.adresse?.codePostal.toLowerCase())
      .concat(' ')
      .concat(props.fournisseur.adresse?.ville.toLowerCase()),
  };
  await fournisseursStore.noterFournisseur(data);
  modale.value.closeModale();
});
</script>

<style scoped>
.garage_notation {
  justify-content: space-around;
  width: 65px;
  top: 0;
  right: 0;
  border: 2px solid #f6a90d;
}

.garage_notation:hover {
  cursor: pointer;
}

.rating_card {
  color: var(--theme-icone) !important;
}

.close_card {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close_card:hover {
  cursor: pointer;
}
</style>
<style>
.v-rating .v-icon {
  padding: 3px !important;
}
</style>
